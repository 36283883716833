<template>
  <div id="forgot-password">
    <FirstTemplateHome>
      <div v-if="statusSend === 0">
        <div class="text-center">
          <img alt="logo" src="@/assets/icon/lock.png" class="mw-100" />

          <h1 class="font-weight-400 mg-b-less">ลืมรหัสผ่าน</h1>
          <p class="mg-b-5">
            ระบบจะส่งรหัสผ่านให้ท่านทางอีเมลเพื่อใช้ในการเข้าสู่ระบบ
          </p>
        </div>
        <el-form
          @submit.native.prevent="submitForgot"
          label-position="top"
          :model="form"
          ref="ruleFormForgot"
          :rules="rules"
        >
          <el-form-item label="อีเมล" prop="email">
            <el-input
              v-model="form.email"
              clearable
              placeholder="กรุณากรอกอีเมล"
            >
            </el-input>
          </el-form-item>

          <el-button
            type="warning"
            native-type="submit"
            round
            :loading="loadingSubmit"
            class="mg-t-2 mg-b-4 w-100"
            >ส่งรหัสผ่านเข้าอีเมล</el-button
          >
          <div class="text-center is-flex ai-center js-center">
            <i class="fas fa-arrow-left mg-r-2"></i>
            <span class="cr-pointer font-16" @click="$router.push(`/login`)"
              >ย้อนกลับ เข้าสู่ระบบ</span
            >
          </div>
        </el-form>
      </div>
      <div v-else-if="statusSend === 1" class="text-center">
        <div>
          <i class="fas fa-check-circle notification color-success mg-y-4"></i>
          <p class="font-weight-400 font-24">
            ระบบส่งรหัสผ่านเข้าอีเมล สำเร็จแล้ว!
          </p>
          <p class="font-20">กรุณาตรวจสอบอีเมลของคุณ</p>
        </div>
        <div class="mg-t-5 mg-y-3 font-18">
          <span class="mg-r-3 color-35">หากไม่ได้รับลิงก์ทางอีเมล?</span>
          <span
            class="cr-pointer color-pending text-decoration-underline"
            @click="submitAgain()"
            >ส่งอีเมลอีกครั้ง</span
          >
        </div>
        <el-button
          type="warning"
          round
          class="mg-y-4 w-100"
          @click="$router.push(`/login`)"
          >กลับไปหน้า เข้าสู่ระบบ</el-button
        >
      </div>
    </FirstTemplateHome>
  </div>
</template>
<script>
import "@/mixin/FirstTemplateHome";
import { HTTP } from "@/service/axios";

export default {
  mounted() {
    window.localStorage.removeItem("UIC");
  },
  data() {
    return {
      statusSend: 0,
      form: {
        email: "",
      },
      loadingSubmit: false,
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitForgot() {
      this.$refs["ruleFormForgot"].validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          HTTP.post(`user/send/reset/password`, this.form)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "ส่งรหัสผ่านเข้าอีเมลสำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
                this.statusSend = 1;
              } else {
                this.$message({
                  message: "ข้อมูลไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$message({
                  message: "ข้อมูลไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            })
            .finally(() => {
              this.loadingSubmit = false;
            });
        }
      });
    },
    submitAgain() {
      HTTP.post(`user/send/reset/password`, this.form)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              title: "ส่งรหัสผ่านเข้าอีเมลสำเร็จ!",
              type: "success",
              customClass: "success",
            });
          } else {
            this.$message({
              message: "ข้อมูลไม่ถูกต้อง",
              type: "error",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.data.status == 422) {
            this.$message({
              message: "ข้อมูลไม่ถูกต้อง",
              type: "error",
              duration: 4000,
            });
          }
        });
    },
  },
};
</script>
